/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2022 Colophon Foundry
 *
 * Licenced to Desana Network Desana Network Limited
 */

@font-face {
  font-family: "visuelt";
  src: url("../public/fonts/visuelt-regular.eot");
  src: url("../public/fonts/visuelt-regular.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/visuelt-regular.woff2") format("woff2"),
    url("../public/fonts/visuelt-regular.woff") format("woff"),
    url("../public/fonts/visuelt-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "visuelt";
  src: url("../public/fonts/visuelt-medium.eot");
  src: url("../public/fonts/visuelt-medium.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/visuelt-medium.woff2") format("woff2"),
    url("../public/fonts/visuelt-medium.woff") format("woff"),
    url("../public/fonts/visuelt-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "visuelt";
  src: url("../public/fonts/visuelt-bold.eot");
  src: url("../public/fonts/visuelt-bold.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/visuelt-bold.woff2") format("woff2"),
    url("../public/fonts/visuelt-bold.woff") format("woff"),
    url("../public/fonts/visuelt-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "visuelt";
  src: url("../public/fonts/visuelt-italic.eot");
  src: url("../public/fonts/visuelt-italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/visuelt-italic.woff2") format("woff2"),
    url("../public/fonts/visuelt-italic.woff") format("woff"),
    url("../public/fonts/visuelt-italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "visuelt";
  src: url("../public/fonts/visuelt-medium-italic.eot");
  src: url("../public/fonts/visuelt-medium-italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/visuelt-medium-italic.woff2") format("woff2"),
    url("../public/fonts/visuelt-medium-italic.woff") format("woff"),
    url("../public/fonts/visuelt-medium-italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "visuelt";
  src: url("../public/fonts/visuelt-bold-italic.eot");
  src: url("../public/fonts/visuelt-bold-italic.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/visuelt-bold-italic.woff2") format("woff2"),
    url("../public/fonts/visuelt-bold-italic.woff") format("woff"),
    url("../public/fonts/visuelt-bold-italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
