@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  font-family: 'visuelt', -apple-system, BlinkMacSystemFont, Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgb(17 24 39/var(--tw-text-opacity));
}

.react-tiny-popover-container {
  z-index: 9999;
}