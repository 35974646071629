.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}


.Button {
  margin-left: 10px;
}

.Form {
position: absolute;
top: 50%; left: 50%;
}



.daterangepicker .ranges li.active {
  background-color: #8381F2 !important;
  color: #fff;
}

.btn-outline-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-border-color: #6c757d;
  --bs-btn-hover-color: none !important;
  --bs-btn-hover-bg: #ffffff !important;
  --bs-btn-hover-border-color: #6c757d;
  --bs-btn-focus-shadow-rgb: 108,117,125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: #0000;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

